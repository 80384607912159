import React from "react";

import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import OfshoreIT from "../Components/OfshoreIT";

function Contact() {

  return ( 
    <div className="home-section">
    <Navbar />
    <OfshoreIT />;
    <Footer />
    </div>
  )
 
}
export default Contact;
