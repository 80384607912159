import React from "react";
import ContactForm from "../Components/ContactForm";
import Navbar from "../Components/Navbar";
import { Helmet } from 'react-helmet';
import Footer from "../Components/Footer";

function Contact() {

  return ( 
    <div className="home-section">
       <Helmet>
        <meta charSet="utf-8" />
        <title>Meta Resources Group - Contact</title>
        <meta name="description" content="Contact us for any query" />
      </Helmet>
    <Navbar />
    <ContactForm />;
    <Footer />
    </div>
  )
 
}

export default Contact;
