import React, { useEffect, useState } from 'react';
import "../Styles/Servicesone.css";
import Navbar from './Navbar';
import Footer from "./Footer";

const StaffingnSearch = () => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);
  return (

    <div className="section-container">

      <div className={`hero-section ${mounted ? 'animate-slide' : ''}`}>
        <div className="text-section">
          <h2 className="text-title">
            STAFFING
          </h2>
        </div>

      </div>
      <div style={{ background: "rgb(14, 45, 78)" }}>
        <div className="text-section">
       
          <p className="text-descritpion" >
            Shifting skills requirements have become a challenge for employers with the demand of accelerating technologies. Technological advancement means searching out the proactive and top talented people in all departments of an organization.
          </p>
          <hr className="line" />
          <h2 className="text-title">
            Why choose MRG for staffing and searching?
          </h2>
          <hr className="line" />
          <p className="text-descritpion" >
          As a specialist, our team understands the human capital solutions marketplace. Whether you are looking to hire contract-based people, to-contract-to-hire, permanent placement or strategic management professionals, we have worked across all industries to help you find the best-suited candidate for your business.
          </p>

          <p className="text-descritpion" >
          We find out people with high-demand skill sets. We hire them on an immediate basis with up to a 48-hour turnaround.
          </p>

          <p className="text-descritpion" >
          Hiring process and managing turnover cost too much capital for a business. Our team ensures that the staffing and recruitment process is tailored to the needs and capacity of clients. We assist by offering a hassle-free approach to creating a realistic budget for your business.
          </p>


          <p className="text-descritpion" >
          We believe in creating a long-term relationship with our clients that helps us deliver insights and results with the changing dynamics. We work tirelessly with the candidate from interviewing to the final placement in the firm.
Our recruiting services will help in finding a proactive candidate. Our staffing process will help fill skill gaps, minimize turnover, and provide a cost structure best suited for a firm or project budget.

          </p>

        </div>


      </div>
    </div>
  )
}

export default StaffingnSearch