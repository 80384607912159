import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function InfoCard(props) {
  return (
    <div className="info-cardsAnother">
      {/* <span className="info-card-icon">
        <FontAwesomeIcon className="info-fa-icon" icon={props.icon} />
      </span> */}
        <img className="image-logo" src = {props.image} />
      <p className="info-card-title">{props.title}</p>
      <p className="info-card-description">{props.description}</p>
    </div>
  );
}

export default InfoCard;
