import React from 'react';
import "../Styles/AppointmentForm.css";
import cali from "../Assets/cali_img.jpg";
import khi from "../Assets/karachi_img.png";
import nyc from "../Assets/nyc_img.png";

// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function ContactForm() {
    return (
        <div className="container">
{/*           
            <div className="column">
                <i className="fas fa-user icon"></i>
                <h2 className='main-heading'>GET IN TOUCH</h2>
                <form>
                    <label htmlFor="name" className="custom-label">Name</label>
                    <input type="text" id="name" name="name" placeholder="Enter Your Name" required />

                    <label htmlFor="email" className="custom-label">Email</label>
                    <input className='input-box' type="email" id="email" name="email" placeholder="email@example.com" required />

                    <label htmlFor="message" className="custom-label">Message</label>
                    <textarea id="message" name="message" rows="9" placeholder="Enter Your Query Here"></textarea>
                    <button type="submit">Submit</button>
                </form>
            </div> */}
            <div className="column">
                <div className="contact-info">
                    <h2 className='contact-text-main'>LOCATIONS</h2>

                    <div className='main-border'>
                    
                    <div className='border-section'>
                            <div className='image-container'>
                                <img src={nyc}alt="New York" className="contact-image" />
                                <div className="overlay">
                                    <p className='contact-text'><strong>NEW YORK</strong></p>
                                    <p className='contact-text'>New York, NY</p>
                                </div>
                            </div>
                        </div>

                        <div className='border-section'>
                            <div className='image-container'>
                                <img src={cali} className="contact-image" />
                                <div className="overlay">
                                    <p className='contact-text'><strong>CALIFORNIA</strong></p>
                                    <p className='contact-text'>Los Angeles, CA</p>
                                </div>
                            </div>
                        </div>

                        <div className='border-section'>
                            <div className='image-container'>
                                <img src={khi}alt="Karachi" className="contact-image" />
                                <div className="overlay">
                                    <p className='contact-text'><strong>KARACHI</strong></p>
                                    <p className='contact-text'>Sindh, Pakistan</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p className='contact-text' style={{marginTop: '46px', color: "#FFF", fontWeight: 800}}>Reach Us Here: </p>


                    <p className='contact-text' style={{color: "#2E8CA2"}}><a style = {{textDecoration: "None", color: "#2E8CA2" }} href="mailto:info@metaresourcesgroup.com">info@metaresourcesgroup.com </a></p>
                    {/* s */}
                </div>

      
            </div>
            {/* <div style={{ height: '400px', width: '100%' }}>
                <LoadScript googleMapsApiKey="YOUR_API_KEY">
                    <GoogleMap
                        mapContainerStyle={{ height: '100%', width: '100%' }}
                        zoom={10}
                        center={defaultCenter}>
                        <Marker position={defaultCenter} />
                    </GoogleMap>
                </LoadScript>
            </div> */}
        </div>
    );
}

export default ContactForm;
