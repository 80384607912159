import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Service from "../Components/Service";
import { Helmet } from 'react-helmet';

function Services() {
  return ( 


    <div className="home-section">
       <Helmet>
        <meta charSet="utf-8" />
        <title>Meta Resources Group - Services</title>
        <meta name="description" content="Our services, what we have to offer." />
      </Helmet>
    <Navbar />
    <Service />;
    <Footer />
    </div>
  )
 
}
export default Services;
