import React, { useEffect, useState } from "react";
import "../Styles/Servicesone.css";
import Staff from "../Assets/staffing.png";
import Consulting from "../Assets/consulting.png";
import Offshore from "../Assets/offshoring.png";
import Analytics from "../Assets/analytics.jpeg";
import solutionservices from "../Assets/sol.jpeg";
import "../Styles/About.css";

const Service = () => {
  const [mounted, setMounted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const observeSection = () => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once section is in view
        }
      });
    }, options);

    const target = document.querySelector(
      ".about-image1",
      ".about-text-content"
    );
    if (target) {
      observer.observe(target);
    }
  };

  useEffect(() => {
    observeSection();
  }, []);

  useEffect(() => {
    setMounted(true);
  }, []);
  return (
    <div className="section-container">
      <div className={`hero-section ${mounted ? "animate-slide" : ""}`}>
        <div className="text-section">
          <h2 className="text-title">SERVICES</h2>
          <div
            className="main-button-div"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
             <button className="hero-btn" type="button">
              <a
                href="#solutiondelivery"
                style={{ textDecoration: "None", color: "white" }}
              >
                Solution Delivery
              </a>
            </button>
            <button className="hero-btn" type="button">
              <a
                href="#staffing"
                style={{ textDecoration: "None", color: "white" }}
              >
                Talent Solution
              </a>
            </button>

            <button className="hero-btn" type="button">
              <a
                href="#analytics"
                style={{ textDecoration: "None", color: "white" }}
              >
                Data Analytics
              </a>
            </button>
          </div>
        </div>
      </div>
      <div style={{ background: "rgb(14, 45, 78)" }}>

      <div
          className="about-section"
          style={{ background: "rgb(14, 45, 78)" }}
        >
          <div className={`about-text-content ${isVisible ? "animated" : ""}`}>
            <h3
              className="about-title"
              style={{ color: "white", paddingLeft: "60px" }}
            >
              <span>1. SOLUTION DELIVERY</span>
            </h3>
            <p
              className="about-description"
              style={{ color: "white", paddingLeft: "60px" }}
              id="solutiondelivery"
            >
             At Meta Resources Group, we are more than just a service provider—we are your partner in navigating the complexities of the digital world. Our comprehensive solutions empower you to transform your business, enhance efficiency, and accelerate growth. With our expertise in consulting, IT modernization, cloud integration, and AI-driven automation, we help you streamline processes and make data-driven decisions. Our advanced cybersecurity services protect your digital assets, ensuring business continuity and peace of mind. We also offer tailored, industry-specific strategies for sectors like finance, healthcare, retail, and manufacturing. Let us help you unlock new possibilities and stay ahead of the competition in a rapidly evolving digital landscape. Choose Meta Resources Group and turn potential into performance.
            </p>

          </div>
          <div
            className="about-image-content"
            style={{ paddingRight: "-19px" }}
          >
            <img
              src={solutionservices}
              className={`about-image1 ${isVisible ? "animate" : ""}`}
            />
          </div>
        </div>




        <div
          className="about-section"
          style={{ background: "rgb(14, 45, 78)" }}
        >
          <div className="about-image-content">
            <img
              src={Staff}
              className={`about-image1 ${isVisible ? "animate" : ""}`}
            />
          </div>

          <div className={`about-text-content ${isVisible ? "animated" : ""}`}>
            <h3 className="about-title" style={{ color: "white" }}>
              <span>2. TALENT SOLUTIONS</span>
            </h3>
            <p
              className="about-description"
              id="staffing"
              style={{ color: "white" }}
            >
              Shifting skills requirements have become a challenge for employers
              with the demand of accelerating technologies. Technological
              advancement means searching out the proactive and top talented
              people in all departments of an organization.
            </p>
          </div>
        </div>
        <div className="text-section">
          <hr className="line" />
          <h2 className="text-title">Why choose MRG for Talent Solutions??</h2>
          <hr className="line" />
          <p className="text-descritpion">
            As a specialist, our team understands the human capital solutions
            marketplace. Whether you are considering contract-based supplemental
            resources, contract-to-hire, permanent placement or strategic
            management professionals, we have worked across all industries to
            help you find the best-suited candidate for your business.
          </p>

          <p className="text-descritpion">
          We find people with high-demand skill sets. We hire them on an
          immediate basis with up to a 48-hour turnaround.
          </p>

          <p className="text-descritpion">
            Re-hiring and turnover processes and management can cost too much
            capital for a business. Our team ensures that the staffing and
            recruitment process is tailored to the needs and capacity of
            clients. We assist by offering a hassle-free approach to creating a
            realistic talent acquisition budget for your business.
          </p>

          <p className="text-descritpion">
            We believe in creating a long-term relationship with our clients
            that helps us deliver insights and results with changing business
            dynamics. We work tirelessly with the candidate from interviewing to
            the final placement in the firm. Our recruiting services will find a
            proactive candidate. Our staffing process will help fill skill gaps,
            minimize turnover, and provide a cost structure best suited for your
            business or project budget.
          </p>
        </div>

        <div
          className="about-section"
          style={{ background: "rgb(14, 45, 78)" }}
        >
          <div className={`about-text-content ${isVisible ? "animated" : ""}`}>
            <h3
              className="about-title"
              style={{ color: "white", paddingLeft: "60px" }}
            >
              <span>3. OFFSHORE</span>
            </h3>
            <p
              className="about-description"
              style={{ color: "white", paddingLeft: "60px" }}
              id="offshore"
            >
              In the digital era, achieving excellence requires true ambition
              and flawless execution. Our multidisciplinary team works
              side-by-side with you to design and execute transformative digital
              businesses through offshore services. Whether you aim to embrace
              new digital capabilities, reimagine your business structure, or
              launch an entirely new digital venture, we are committed to
              setting a new standard of excellence.
            </p>

            <p
              className="about-description"
              style={{ color: "white", paddingLeft: "60px" }}
            >
              Our offshore services provide access to qualified learning and IT
              professionals who integrate traditional and emerging technologies
              with leadership and behavioral learning programs. This combination
              ensures that your business heads in the right direction,
              leveraging global expertise to drive innovation and growth.
            </p>
          </div>
          <div
            className="about-image-content"
            style={{ paddingRight: "-19px" }}
          >
            <img
              src={Offshore}
              className={`about-image1 ${isVisible ? "animate" : ""}`}
            />
          </div>
        </div>

        <div
          className="about-section"
          style={{ background: "rgb(14, 45, 78)" }}
        >
          <div className="about-image-content">
            <img
              src={Consulting}
              className={`about-image1 ${isVisible ? "animate" : ""}`}
            />
          </div>

          <div className={`about-text-content ${isVisible ? "animated" : ""}`}>
            <h3 className="about-title" style={{ color: "white" }}>
              <span>4. CONSULTING SERVICES</span>
            </h3>
            <p
              className="about-description"
              id="consulting"
              style={{ color: "white" }}
            >
              Meta Resources Group provides your business with high-end
              consultation services, including strategic planning, operations,
              technology, advanced analytics, corporate finance, mergers and
              acquisitions. Our company provides the professionals, trainers,
              tools and infrastructure that will give your business a boost.
            </p>

            <p className="about-description" style={{ color: "white" }}>
              Our qualified training and IT professionals create an amalgam of
              traditional and emerging technologies, leadership and behavioral
              learning programs to streamline your business.
            </p>
          </div>
        </div>

        <div className="text-section">
          <hr className="line" />
          <h2 className="text-title">Professional IT Consulting Services</h2>
          <hr className="line" />
          <p className="text-descritpion" style={{ paddingBottom: "5 0px" }}>
            We combine imagination with technology to help enterprises grow in
            an age of digital transformation. We design, implement, and manage
            all organization’s functions. We take pride in standing at the
            forefront of all emerging trends in the digital world. By immersing
            ourselves in your business insights and conducting thorough
            research, we deliver result-driven solutions across all aspects of
            technology. Utilizing the best IT professionals, we exceed your
            business expectations from project management and process
            optimization to application development, digital strategies
            formulation and deployment, data analytics, and training. Our
            professionals assist you in gaining deeper insights into your
            business, thanks to better and faster decision-making. We help you
            turn your data assets into a competitive advantage through advanced
            data analytics. We optimize your organizational objectives and
            provide you with a roadmap through a data-driven and value-based
            approach. We collaborate every day to help, connect, and communicate
            with you to understand your ideas, capabilities, and objectives. Our
            consultation services extend beyond formulating and deploying new
            strategies. Our experts ensure optimal performance of resources by
            following a robust performance management process. We stay with you
            from the beginning to the guaranteed quality work delivery.
            Strengthen your business by implementing our high-end consulting
            services and leveraging our comprehensive training programs.
          </p>
        </div>
        <div
          className="about-section"
          style={{ background: "rgb(14, 45, 78)" }}
        >
          <div className={`about-text-content ${isVisible ? "animated" : ""}`}>
            <h3
              className="about-title"
              style={{ color: "white", paddingLeft: "60px" }}
            >
              <span>5. DATA ANALYTICS</span>
            </h3>
            <p
              className="about-description"
              style={{ color: "white", paddingLeft: "60px" }}
              id="analytics"
            >
             Our Data Analytics service empowers your business to harness the full potential of data, transforming it into actionable insights and strategic value. We employ cutting-edge analytics tools and techniques to collect, process, and analyze vast amounts of data from various sources. By doing so, we uncover hidden patterns, trends, and correlations that drive informed decision-making.
            </p>

            <p
              className="about-description"
              style={{ color: "white", paddingLeft: "60px" }}
            >
              Our team of expert data scientists and analysts work closely with you to understand your specific business needs, ensuring that our solutions are tailored to provide maximum impact. Whether it’s enhancing operational efficiency, improving customer experience, or identifying new revenue opportunities, our data analytics service equips you with the knowledge and tools to stay ahead in a competitive market. We translate complex data into clear, concise reports and dashboards, providing you with a comprehensive understanding of your business landscape. With our data-driven approach, you can confidently steer your organization towards sustained growth and innovation.
            </p>
          </div>
          <div
            className="about-image-content"
            style={{ paddingRight: "-19px" }}
          >
            <img
              src={Analytics}
              className={`about-image1 ${isVisible ? "animate" : ""}`}
            />
          </div>
        </div>
        </div>
      </div>

  );
};

export default Service;
