import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Navbar.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../Assets/logo-nav.png";

function Navbar() {
  const [nav, setNav] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const openNav = () => {
    setNav(!nav);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };



  // const handleChatBtnClick = () => {
  //   if (!isButtonDisabled) {
  //     toast.info("Experiencing high traffic, Please wait a moment.", {
  //       position: toast.POSITION.TOP_CENTER,
  //       onOpen: () => setIsButtonDisabled(true),
  //       onClose: () => setIsButtonDisabled(false),
  //     });
  //   }
  // };

  return (
    <div className="navbar-section">
      <h1 className="navbar-title">
        <Link to="/">
          <img src={logo} />
        </Link>
      </h1>

      {/* Desktop */}
      <ul className="navbar-items">
        <li>
          <Link to="/" className="navbar-links">
            Home
          </Link>
        </li>
        <li>
          <Link to={"/about"} className="navbar-links">About</Link>

        </li>
        <li>
          <a href="https://metaresourcesgroup.zohorecruit.com/jobs/Careers" className="navbar-links">
            Job Openings
          </a>
        </li>

        <li>
        <Link to="/services" className="navbar-links">Services</Link>
        </li>

        {/* <li ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <a className="navbar-links" style={{ cursor: 'pointer' }}>
            Services
          </a>
          {isOpen && (
            <div className="dropdown-content">
             
              <Link to="/consultingservices" className="navbar-links">Consulting Services</Link>
              <Link to="/c" className="navbar-links">Staffing & Search</Link>
              <Link to="/offshore" className="navbar-links">Offshore IT</Link>
            </div>
          )}
        </li> */}
        <li>

        <Link to={"/contact"} className="navbar-links">Locations</Link>
        </li>
      </ul>

      {/* <button
        className="navbar-btn"
        type="button"
        disabled={isButtonDisabled}
        onClick={handleChatBtnClick}
      >
        <FontAwesomeIcon icon={faCommentDots} /> Live Chat
      </button> */}

      {/* Mobile */}
      <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
        <div onClick={openNav} className="mobile-navbar-close">
          <FontAwesomeIcon icon={faXmark} className="hamb-icon" />
        </div>

        <ul className="mobile-navbar-links">
        <li>
            <Link onClick={openNav} to="/">Home</Link>
          </li>
          <li>
            <Link onClick={openNav} to="/about">About</Link>
          </li>
          <li>
            <a href="https://metaresourcesgroup.zohorecruit.com/jobs/Careers">Job Openings</a>
          </li>
          <li ref={dropdownRef}>
            <Link onClick={toggleDropdown} className="navbar-links" style={{ cursor: 'pointer', fontSize: '25px' }} to="/services">Services</Link>
          </li>
          <li>
            <Link onClick={openNav} to="/contact">Contact Us</Link>
          </li>
          {/* <li>
            <a onClick={openNav} href="#">
              Contact
            </a>
          </li> */}
        </ul>
      </div>

      <div className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          onClick={openNav}
          className="hamb-icon"
        />
      </div>
    </div>
  );

}

export default Navbar;


 {/* {isOpen && (
            <div className="dropdown-content">
             
              <Link to="/consultingservices" className="navbar-links">Consulting Services</Link>
              <Link to="/staffing" className="navbar-links">Staffing & Search</Link>
              <Link to="/offshore" className="navbar-links">Offshore IT</Link>
            </div>
          )} */}