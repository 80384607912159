import React, { useState, useEffect } from 'react';
import '../Styles/Preloader.css'; 
import logo from "../Assets/icon.png";

const Preloader = () => {
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const preloaderShownBefore = localStorage.getItem('preloaderShown');

    if (preloaderShownBefore) {
      setShowPreloader(false);
    } else {

      localStorage.setItem('preloaderShown', true);
    }
  }, []);

  return (
    <div className={`preloader ${showPreloader ? 'visible' : 'hidden'}`}>
        <img src={logo}/>
    </div>
  );
};

export default Preloader;