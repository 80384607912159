import React from 'react'
import "../Styles/Servicesone.css";
import Navbar from './Navbar';
import Footer from "./Footer";

const Consulting = () => {
  return (

    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <h2 className="text-title">
            CONSULTING SERVICES
          </h2>
        </div>

      </div>
      <div style={{ background: "rgb(14, 45, 78)" }}>
        <div className="text-section">
          <p className="text-descritpion" >
            Meta Resources Group aims to provide your business with high-end consultation services, including strategic planning, operations, technology, advanced analytics, corporate finance, mergers and acquisitions. Our company promises to offer you the professionals, trainers, tools and infrastructure that will give your business a boost.
          </p>
          <hr className="line" />
          <h2 className="text-title">
            Professional IT Consulting Services
          </h2>
          <hr className="line" />
          <p className="text-descritpion" >
            We combine imagination with technology to help enterprises grow in an age of digital transformation. We design, implement and manage all organization’s functions.  We feel pride in standing at the forefront of all emerging trends in the digital world. We indulge in your business insights, and after thorough research, we come up with result-driven solutions in all aspects of technology.
            We utilize the best IT professionals to exceed your business expectations from project management and process optimization to application development, digital strategies formulation and deployment.
            Our professionals assist you in delivering insight into your business. Thanks to better and faster decision-making, we can help you turn your data assets into a competitive advantage. We optimize your organizational objectives and provide you with a roadmap through data-driven and value-based approach.
            We collaborate every day to help, connect and communicate with you to know your ideas, capabilities and objectives. Our consultation services are not merely confined to formulating and deploying the new strategies, but our experts ensure the best performance of resources by following a performance management process. We stay with you from the beginning to the guaranteed quality work delivery.  Strengthen your business by implementing our high-end consulting services.

          </p>
        </div>
      </div>
    </div>
  )
}

export default Consulting