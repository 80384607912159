import React from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Consulting from "../Components/Consulting";

function ConsultingServices() {
  return ( 
    <div className="home-section">
    <Navbar />
    <Consulting />;
    <Footer />
    </div>
  )
 
}

export default ConsultingServices;
