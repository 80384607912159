import React from "react";
import ContactForm from "../Components/ContactForm";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import StaffingnSearch from "../Components/StaffingnSearch";

function Contact() {

  return ( 
    <div className="home-section">
    <Navbar />
    <StaffingnSearch />;
    <Footer />
    </div>
  )
 
}

export default Contact;
