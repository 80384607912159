import React, { useEffect, useState } from "react";
import InformationCard from "./InformationCard";
import InfoCard from "./infoCard";
import { faLaptopFile, faUsers, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";
import analytics from "../Assets/analytics.png";
import consult from "../Assets/consult.png";
import shake from "../Assets/shake.png";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

function Info() {
  const [isVisible, setIsVisible] = useState(false);

  const observeSection = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.3, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target); // Stop observing once section is in view
        }
      });
    }, options);

    const target = document.querySelector('.info-title', '.info-description');
    if (target) {
      observer.observe(target);
    }
  };


  useEffect(() => {
    observeSection();
  }, []);


  return (
    <div className="info-section" id="services">
      <div className="info-title-content">
        <h3 className={`info-title ${isVisible ? 'animate' : ''}`}>
          <span>What We Deliver</span>
        </h3>
        <p className={`info-description ${isVisible ? 'animate' : ''}`}>
        Your business is our business. We examine your business with diverse talent and unmatched IT business consulting services to create innovative IT solutions. Solve your business problems with our top-notch solutions.
        </p>
      </div>

      <div className="info-cards-content">
        <Link to="/services#solutiondelivery" style = {{textDecoration: 'None'}} >
          <InformationCard
            image={analytics}
            title="SOLUTION DELIVERY"
            description="Transform your business with Meta Resources Group. We offer cutting-edge digital solutions, from consulting and cloud integration to AI, automation, and cybersecurity, all designed to optimize your operations and drive growth. Partner with us to turn innovation into your competitive edge. "


          />
        </Link>
        <Link to="/services#staffing" style = {{textDecoration: 'None'}} >
          <InfoCard
            image={shake}
            title="TALENT SOLUTIONS"
            description="As a specialist, our team understands the human capital solutions marketplace. Whether you are looking to hire contract-based people, to-contract-to-hire, permanent placement or strategic management professionals, we have worked across all industries to help you find the best-suited candidate for your business."


          />
        </Link>

        <Link to="/services#analytics" style = {{textDecoration: 'None'}}>
          <InformationCard
            image={consult}
            title="DATA ANALYTICS"
            description="You need a true ambition and flawless execution to excel in the digital era. Our multidisciplinary team work side-by-side with you to design and execute transformative digital businesses. Whether you want to embrace new digital capabilities, reimagine your business structure, or launch an entirely new digital venture, we are with you to set a new standard of excellence."

          />
        </Link>
      </div>
    </div>
  );
}

export default Info;
