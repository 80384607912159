import React from "react";
import "../Styles/Footer.css";
import { Link } from "react-router-dom";
import logo from "../Assets/logo-footer.png";


function Footer() {
  return (
    <div className="footer-section">
      <div className="footer-container">
       
         <Link to="/">
         <img src = {logo} />
        </Link>

        {/* <p className="ft-list-title">Quick links</p>
        <div className="ft-list">
          <ul className="ft-list-items">
            <li>
              <a href="/">Home</a>
            </li>
            </ul>
            <ul className="ft-list-items">
            <li>
              <a href="/about">About Us</a>
            </li>
            </ul>
            <ul className="ft-list-items">
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            </ul>
            <ul className="ft-list-items">
            <li>
              <a href="/legal">Privacy & Policy</a>
            </li>
            </ul>
            <ul className="ft-list-items">
            <li>
              <a href="/terms">Terms & Services </a>
            </li>
            </ul>
        </div> 

        {/* <div className="ft-list">
          <ul className="ft-list-items">
           
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/terms"}>Terms of Services</Link>
            </li>
             
          </ul>
        </div> */}

        <div className="ft-list">
          {/* <ul className="ft-list-items">
          <p className="ft-list-headingTwo">We are Present in</p>
            <li>
              <a> New York City</a>
            </li>
            <li>
              <a>California</a>
            </li>
            <li>
              <a>Karachi</a>
            </li>
          </ul> */}
          <ul className="ft-list-itemsTwo">
          <p className="ft-list-headingTwoTwo">Email Us At</p>
            <li>
              <a href="mailto:info@metaresourcesgroup.com">info@metaresourcesgroup.com</a>
            </li>
            </ul>
            <ul className="ft-list-itemsTwo">
            <li>
              <Link to={"/legal"}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={"/terms"}>Terms of Services</Link>
            </li>
            
          </ul>
          {/* <ul className="ft-list-items"> */}
          {/* <p className="ft-list-headingTwo">Quick Links</p> */}
          {/* <ul className="ft-list-items">
            <li>
              <a href="/">Home</a>
            </li>
            </ul>
            <ul className="ft-list-items" >
            <li>
              <a href="/about">About Us</a>
            </li>
            </ul>
            <ul className="ft-list-items">
            <li>
              <a href="/contact">Contact Us</a>
            </li>
            </ul> */}
            {/* <ul className="ft-list-items">
            <li>
              <a href="/legal">Privacy & Policy</a>
            </li>
            </ul>
            <ul className="ft-list-items">
            <li>
              <a href="/terms">Terms & Services </a>
            </li>
            </ul>
          </ul> */}
         
        </div>
        
      </div>
      <div className="ft-copyright">
        {/* <p>Powered by <a href="https://hashedapp.com/" target="_blank" className="ft-copyright-in">Hashed App </a> © All rights reserved.</p> */}

        <ul className="ft-social-links">
          <li>
            <a
              href="https://www.linkedin.com/company/meta-resources-group/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </li>

          <li>
          </li>

          <li>
            <a
              href="mailto:info@metaresourcesgroup.com"
              title="Mail"
              target="_blank"
              rel="noopener noreferrer"
            >
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
