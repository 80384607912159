import React from 'react'
import "../Styles/Servicesone.css";
import Navbar from './Navbar';
import Footer from "./Footer";

const OfshoreIT = () => {
  return (

    <div className="section-container">

      <div className="hero-section">
        <div className="text-section">
          <h2 className="text-title">
            OFFSHORE IT SERVICES
          </h2>
        </div>

      </div>
      <div style={{ background: "rgb(14, 45, 78)" }}>
        <div className="text-section">
          <p className="text-descritpion" >
          You need a true ambition and flawless execution to excel in the digital era. Our multidisciplinary team work side-by-side with you to design and execute transformative digital businesses. Whether you want to embrace new digital capabilities, reimagine your business structure, or launch an entirely new digital venture, we are with you to set a new standard of excellence
          </p>
          <p className="text-descritpion" >
          Our qualified learning and IT professionals create an amalgam of traditional and emerging technologies and leadership and behavioural learning programs to give your business the right direction.


          </p>


        </div>

      </div>
    </div>
  )
}

export default OfshoreIT