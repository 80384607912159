import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import About from "../Components/About";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Doctor from "../Assets/aboutPage.png";
import Supp from "../Assets/supplier-img.png";
import "../Styles/Reviews.css";
import "../Styles/About.css";

function Supplier() {
  const [isVisible, setIsVisible] = useState(false);

  const observeSection = () => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const target = document.querySelector(".rw-text-content");
    if (target) {
      observer.observe(target);
    }
  };

  useEffect(() => {
    observeSection();
  }, []);

  return (
    <div className="home-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Meta Resources Group - Our Supplier Commitment</title>
        <meta
          name="description"
          content="Our passionate and purposeful team ensures to solve complex business problems by combining cutting-edge digital solutions and customized client delivery processes."
        />
      </Helmet>
      <Navbar />
      <div className="about-section">
        <div className="about-image-content">
          <img src={Supp} className="about-image1" style={{ opacity: 1 }} />
        </div>

        <div className="about-text-content" style={{ opacity: 1 }}>
          <h3 className="about-title" style={{ fontSize: '35px' }}>
            <span>Our Supplier Commitment</span>
          </h3>
          <p className="about-description">
            At Meta Resources Group, we are committed to maintaining the highest
            ethical standards in everything we do, including how we select and
            manage our suppliers. As an IT professional services firm, we
            recognize that our success is closely tied to the integrity,
            responsibility, and quality of the suppliers with whom we partner.
            This commitment reflects our ongoing dedication to delivering
            excellent services to our clients while fostering sustainability,
            diversity, and corporate responsibility throughout our supply chain.
          </p>
          <p className="about-description">
            We hold ourselves—and our suppliers—accountable to the following
            principles, which guide our procurement processes and business
            relationships.
          </p>
        </div>
      </div>

      <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        BUSINESS, ETHICS AND INTEGRITY
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          Honesty and Transparency: We maintain honesty and transparency in all our business dealings, ensuring clear communication, accurate documentation, and integrity in all our transactions. Our suppliers are expected to operate with the same level of transparency and accountability.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Fair Business Practices: We adhere to ethical business practices and require our suppliers to do the same. We do not tolerate corruption, bribery, or any form of unethical conduct. Our procurement decisions are based on merit, value, and the best interests of our customers.
          </span>
        </p>
        
      </div>
        <div className="rw-text-content" style={{ opacity: 1 }}>
          <h3 className="supplier-title">
            <span>GOVERNANCE & ACCOUNTABILITY</span>
          </h3>
          <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Corporate Governance: We are committed to maintaining strong governance structures that ensure accountability at every level of our operations. This includes clear lines of responsibility, robust internal controls, and senior management oversight of compliance.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Supplier Oversight: We expect our suppliers to adopt sound governance practices that align with our standards. Suppliers must have mechanisms in place to ensure that their operations meet ethical, legal, and regulatory requirements. We maintain oversight through regular audits, evaluations, and reviews to ensure compliance with our standards.
          </span>
        </p>
        </div>
        <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        COMPLIANCE WITH LAWS & REGULATIONS
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          Legal Compliance: We comply with all relevant local, national, and international laws and regulations, including data protection, privacy, labor, and environmental laws. We expect the same from our suppliers and require that they comply with all applicable laws in the regions where they operate.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Export Controls & Trade Sanctions: We respect all relevant export control laws and trade sanctions. Our suppliers must ensure that they do not engage in business activities that violate export restrictions or international sanctions.
          </span>
        </p>
        
      </div>
      <div className="rw-text-content" style={{ opacity: 1 }}>
          <h3 className="supplier-title">
          <span>
            COMMITMENT TO QUALITY & CONTINUOUS IMPROVEMENT</span>
          </h3>
          <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Quality Standards: We are dedicated to delivering exceptional service quality and expect our suppliers to do the same. Our suppliers are required to maintain and adhere to high standards of quality in the products and services they provide. We support a culture of continuous improvement and encourage suppliers to regularly assess and improve their processes.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Performance Metrics: We work with suppliers who share our commitment to performance excellence. Suppliers must meet agreed-upon service levels and key performance indicators (KPIs), and we continuously monitor their performance to ensure the highest quality outcomes for our customers.
          </span>
        </p>
        </div>
        <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        ENVIRONMENTAL RESPONSIBILITY & SUSTAINABILITY
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          Sustainable Practices: We are committed to environmental sustainability in all areas of our business. We work with suppliers who prioritize sustainability by reducing their environmental impact, conserving resources, and implementing eco-friendly practices.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Environmental Management: We encourage our suppliers to adopt formal Environmental Management Systems (EMS) such as ISO 14001, which focus on minimizing environmental risks, improving energy efficiency, and reducing waste.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Climate Action: As part of our sustainability efforts, we aim to reduce our carbon footprint and seek suppliers who share this commitment to climate action, waste reduction, and resource conservation.
          </span>
        </p>
      </div>
      <div className="rw-text-content" style={{ opacity: 1 }}>
          <h3 className="supplier-title">
            <span>DATA SECURITY & PRIVACY</span>
          </h3>
          <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Information Security: As a provider of IT professional services, we place the highest priority on protecting the confidentiality and security of customer data. Our suppliers are expected to implement robust information security measures to safeguard data and prevent unauthorized access, theft, or breaches.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Data Privacy: We are committed to respecting customer privacy and ensuring that any personal data shared with our suppliers is handled responsibly, in accordance with relevant data protection laws such as GDPR or CCPA. Suppliers must ensure that they meet or exceed industry standards for data protection.
          </span>
        </p>
        </div>
      <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        LABOR PRACTICES & HUMAN RIGHTS
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          Fair Labor Practices: We are committed to upholding the highest standards of labor rights, including fair wages, safe working conditions, and respect for workers' rights. We work only with suppliers who do not engage in forced labor, child labor, or any form of exploitation.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Non-Discrimination: We expect our suppliers to foster a diverse and inclusive workplace, providing equal opportunities for all employees regardless of race, gender, ethnicity, religion, disability, or any other protected characteristic.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Health & Safety: The health and safety of workers is a top priority for us. We require our suppliers to comply with health and safety regulations and to take steps to ensure a safe working environment for all employees.
          </span>
        </p>
      </div>
        
      <div className="rw-text-content" style={{ opacity: 1 }}>
          <h3 className="supplier-title">
            <span>ANTI-CORRUPTION & ETHICAL CONDUCT</span>
          </h3>
          <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Zero Tolerance for Corruption: We have a zero-tolerance policy for corruption and bribery. We expect our suppliers to follow strict anti-bribery and anti-corruption laws, prohibiting any form of unethical financial transactions, gifts, or incentives.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Transparency in Dealings: We value transparency in all our supplier relationships. Our procurement processes are designed to ensure that business decisions are made based on merit and integrity, and that all supplier transactions are transparent and accountable.
          </span>
        </p>
        </div>
        <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        COMMITMENT TO DIVERSITY & INCLUSION
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          Diversity in the Supply Chain: We actively encourage diversity within our supply chain and seek to partner with suppliers that prioritize diversity and inclusion in their workforce, operations, and leadership.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
          Supporting Minority-Owned and Local Businesses: We are committed to fostering diversity by considering minority-owned, women-owned, and local suppliers as part of our procurement strategy, where applicable.
          </span>
        </p>
      </div>
    
      <div className="rw-text-content" style={{ opacity: 1 }}>
          <h3 className="supplier-title">
            <span>MONITORING & CONTINUOUS ENGAGEMENT</span>
          </h3>
          <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Ongoing Supplier Evaluation: We regularly evaluate our suppliers to ensure they continue to meet our expectations and standards. Our supplier management processes include regular assessments, audits, and performance reviews to ensure compliance with this commitment.
          </span>
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews" style={{color: 'black'}}>
          Collaboration & Improvement: We view our suppliers as key partners in delivering quality services to our customers. We are committed to open communication, collaboration, and continuous improvement in all aspects of our supplier relationships. We work closely with our suppliers to resolve issues and implement improvements that benefit our customers.
          </span>
        </p>
        </div>
        <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        OUR PROMISE TO CUSTOMERS
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          We believe that by upholding these principles and working with suppliers who share our commitment to ethics, quality, and sustainability, we can continue to deliver the highest standards of service to our customers. At Meta Resources Group, we are dedicated to driving positive change not just within our organization, but across our entire supply chain. Our customers can trust that the partners we choose to work with are held to the highest ethical, legal, and operational standards.

          </span>
        </p>
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        A COMMITMENT TO EXCELLENCE
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          By partnering with us, our customers can be confident that Meta Resources Group and our suppliers are working together to create lasting value, while maintaining a commitment to governance, ethical conduct, environmental stewardship, and continuous improvement. We pledge to uphold these standards across all of our supplier relationships, as part of our ongoing effort to support our customers and the communities we serve.

          </span>
        </p>
       
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          This Supplier Commitment is posted on our website as a reflection of our dedication to maintaining high standards of ethical conduct, sustainability, and governance throughout our operations and supply chain.
          For any inquiries or feedback, please contact us at: accounts@metaresourcesgroup.com
          </span>
        </p>
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
        ACKNOWLEDGEMENT & ACCEPTANCE
        </p>
        <p className="rw-text-format" >
          <span className="rw-reviews" >
          By working with Meta Resources Group, suppliers and customers alike acknowledge and support the principles outlined in this Supplier Commitment, which serves as a foundational element of our mutual success and long-term partnership.

          </span>
        </p>

      </div>

  
      {/* <p className="rw-text-description">A record of helping businesses that speaks for itself</p>
      <div className="text-stats-abt" style={{padding: "150px", background: "white"}}>
            <div className="text-stats-container">
              <p>3+</p>
              <p>Regions Across</p>
            </div>

            <div className="text-stats-container">
              <p>100%</p>
              <p>Satisfied Clients</p>
            </div>

            <div className="text-stats-container">
              <p>10X</p>
              <p>Growth Expectation</p>
            </div>
          </div> */}

      <Footer />
    </div>
  );
}
export default Supplier;
