import React from "react";
import LegalDocs from "../Components/LegalDocs";
import { Helmet } from 'react-helmet';
function Legal() {
  <Helmet>
  <meta charSet="utf-8" />
  <title>Meta Resources Group - Privacy </title>
  <meta name="description" content="Privacy and Policy. Read Here" />
</Helmet>
  return <LegalDocs />;
}

export default Legal;
