import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import About from "../Components/About";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Doctor from "../Assets/aboutPage.png";
import { Link } from "react-router-dom";
import supp from "../Assets/supplier-img.png"
import "../Styles/Reviews.css";
import "../Styles/About.css";

function Abt() {
  const [isVisible, setIsVisible] = useState(false);

  const observeSection = () => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    const target = document.querySelector(".rw-text-content");
    if (target) {
      observer.observe(target);
    }
  };

  useEffect(() => {
    observeSection();
  }, []);

  return (
    <div className="home-section">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Meta Resources Group - About</title>
        <meta
          name="description"
          content="Our passionate and purposeful team ensures to solve complex business problems by combining cutting-edge digital solutions and customized client delivery processes."
        />
      </Helmet>
      <Navbar />
      <div className="about-section">
        <div className="about-image-content">
          <img src={Doctor} className="about-image1" style={{ opacity: 1 }} />
        </div>

        <div className="about-text-content" style={{ opacity: 1 }}>
          <h3 className="about-title">
            <span>Who We Are</span>
          </h3>
          <p className="about-description">
            Meta Resources Group provides businesses with expert delivery teams,
            solutions architecture, IT staffing and training, including onshore
            and offshore solutions. Our passionate and purposeful team solves
            complex business problems by combining cutting-edge digital
            solutions with customized client delivery processes. We will work
            with you to expand your market share. grow revenue or minimize
            costs.
          </p>

         
        </div>
      </div>

      <div className="about-section">
      <div className="about-text-content" style={{ opacity: 1, paddingLeft:'25px' }}>
        <h3 className="supplier-main-heading">
            <Link
              to="/about/supplier"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <span>OUR SUPPLIER COMMITMENT</span>
            </Link>
          </h3>
          <p className="about-description">
          At Meta Resources Group, we are committed to maintaining the highest ethical standards in everything we do, including how we select and manage our suppliers. As an IT professional services firm, we recognize that our success is closely tied to the integrity, responsibility, and quality of the suppliers with whom we partner. This commitment reflects our ongoing dedication to delivering excellent services to our clients while fostering sustainability, diversity, and corporate responsibility throughout our supply chain.
          </p>
        </div>
        <div className="about-image-content">
          <img src={supp} className="about-image1" style={{ opacity: 1 }} />
        </div>

        
      </div>


      <div
        className={`rw-text-content ${isVisible ? "animate" : ""}`}
        style={{
          backgroundImage: "linear-gradient(to bottom right, #000000, #0E4887)",
        }}
      >
        <p className="rw-text-desc" style={{ color: "#29ABE2" }}>
          "We bring deep commitment with industry expertise to solve business
          problems"
        </p>
        <p className="rw-text-format">
          <span className="rw-reviews">
            We help you to transform your business by providing an unmatched
            experience with thoughtful and knowledgeable consultants to create
            customized solutions. Make an impact in the business world with us.
          </span>
        </p>
        <div className="ft-copyright">
          <button
            className="hero-btn"
            type="button"
            onClick={() =>
              window.open(
                "https://metaresourcesgroup.zohorecruit.com/jobs/Careers",
                "_blank"
              )
            }
          >
            View Job Openings
          </button>
          <ul className="ft-social-links" style={{ gap: "50px" }}>
            <li>
              <a
                href="https://www.linkedin.com/company/meta-resources-group/"
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512"
                >
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="mailto:info@metaresourcesgroup.com"
                title="Mail"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <p className="rw-text-description">A record of helping businesses that speaks for itself</p>
      <div className="text-stats-abt" style={{padding: "150px", background: "white"}}>
            <div className="text-stats-container">
              <p>3+</p>
              <p>Regions Across</p>
            </div>

            <div className="text-stats-container">
              <p>100%</p>
              <p>Satisfied Clients</p>
            </div>

            <div className="text-stats-container">
              <p>10X</p>
              <p>Growth Expectation</p>
            </div>
          </div> */}

      <Footer />
    </div>
  );
}

export default Abt;
